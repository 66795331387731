@media screen and (min-width: 30em) {
  .v-measure-wide-ns {
    max-height: 34em;
  }
}
.focus-outline-0:focus {
  outline: 0;
}

input.no-spin-box {
  -moz-appearance: textfield;
}
input.no-spin-box::-webkit-outer-spin-button,
input.no-spin-box::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.elm-emoji-img {
  max-width: initial;
  height: 1em;
}
